import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es__header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a
                          className="first"
                          href="/uk/our-products/moisturising/"
                        >
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es__header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es__header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/know_the_sun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/know_the_sun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/conozca-el-sol/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/sobre_o_sol/">PT</a>
                      </li>
                      <li>
                        <a href="/it/know_the_sun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/know_the_sun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="kts-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt="Our guide to enjoying the sun safely"
                    src="/assets/images/2017-pizbuin_1680x337px_KnowTheSun.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/know_the_sun_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <div className="kts-page container">
              <div className="kts-page__intro">
                <h1>KNOW THE SUN</h1>
                <h3>Our guide to enjoying the sun safely</h3>
                <p>
                  All PIZ BUIN<sup>®</sup> suncare products contain our advanced
                  UVA/UVB sun protection to ensure you get effective sun
                  protection. However, we believe we can never know too much
                  about the sun and the potential effects it can have on us.
                  Below are some important facts about the sun and how to best
                  enjoy it responsibly.
                </p>
              </div>
              <section id="content">
                <div className="innerContent">
                  <ul className="ktsBlocks">
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">THE SUN</h2>
                        <p className="toggle__sub-title">
                          UVA and UVB radiation are familiar terms, but what do
                          they mean and how do they affect us? Find out here.{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul id="container_thesun">
                        <li>
                          <div
                            id="ccm-layout-wrapper-5710"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-4-1"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-1 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-4-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  <h2>Radiation</h2>
                                  <p className="p1">
                                    The sun provides the earth with life-giving
                                    energy and is the major factor in
                                    determining the earth's climate and weather.
                                    The sun not only sends visible light to the
                                    earth but also invisible radiation that can
                                    have a negative impact on our health if
                                    received in doses that are too high.
                                  </p>
                                  <p className="p1">
                                    Solar radiation consists of a broad variety
                                    of rays. Some don't even reach the earth as
                                    they are blocked by the ozone layer.
                                    Radiation that reaches the earth consists of
                                    visible rays, infrared rays (IR) and
                                    ultraviolet rays (UV). UV radiation is
                                    further divided into UVA, UVB and UVC rays,
                                    depending on their wavelength. UVC rays are
                                    also blocked by the ozone layer.
                                  </p>
                                  <div
                                    id="blockStyle613TheSunLayout1Cell162"
                                    className=" ccm-block-styles"
                                  >
                                    <img
                                      border="0"
                                      className="ccm-image-block"
                                      alt="Our guide to enjoying the sun safely"
                                      src="/assets/images/Radiation_graph-Recovered.png"
                                      width="640"
                                      height="380"
                                    />
                                  </div>
                                  <h2>Visible light</h2>
                                  <p>
                                    Visible light comprises all the colours we
                                    see with the naked eye.
                                  </p>
                                  <p> </p>
                                  <h2>Infrared (IR) rays</h2>
                                  <p>
                                    Infrared (IR) rays transport heat energy,
                                    enabling us to experience heat.
                                  </p>
                                  <p> </p>
                                  <h2>Ultraviolet (UV) rays</h2>
                                  <p>
                                    Ultraviolet (UV) rays are invisible and are
                                    divided into UVA, UVB and UVC rays. UVA rays
                                    cause long-term skin damage, including skin
                                    cancer and premature skin ageing, while UVB
                                    rays are the main cause of sunburn.
                                    Approximately 10 times more UVA rays reach
                                    the earth's surface than UVB rays. UVC rays
                                    are absorbed by the earth's atmosphere and
                                    don't affect the skin.
                                  </p>
                                  <p>
                                    The intensity of sun radiation is dependent
                                    on several factors, including the time of
                                    year, time of day, latitude, altitude,
                                    ground reflections and weather conditions.
                                    The sun is brighter at midday, for example,
                                    and at high altitudes, less UV radiation is
                                    absorbed by the thinner atmosphere.
                                  </p>
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="ccm-layout-wrapper-5711"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-8-3"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-3 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-8-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  &nbsp;
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle650TheSun83"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_01.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">80%</span> of UV rays
                                  can penetrate clouds
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle665TheSun91"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_02.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Clean snow reflects up to{" "}
                                  <span className="orange">80%</span> of all UV
                                  rays
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle666TheSun92"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_03.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  <span className="orange">60%</span> of UV rays
                                  occur between 10am and 2pm
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle667TheSun93"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_04.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  UV radiation increases by{" "}
                                  <span className="orange">4%</span> with each{" "}
                                  <span className="orange">300</span> metres in
                                  altitude
                                </p>
                                <div> </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle668TheSun94"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_05.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Shade can reduce UV exposure by{" "}
                                  <span className="orange">50%</span> or more
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle669TheSun95"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_06.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  At half a metre under the water, UV is still{" "}
                                  <span className="orange">40%</span> as intense
                                  as on the surface
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle670TheSun96"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_07.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  White sand reflects up to{" "}
                                  <span className="orange">15%</span> of UV rays
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle700TheSun97"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_08.png"
                                  width="320"
                                  height="325"
                                  alt="Our guide to enjoying the sun safely"
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Water reflects about{" "}
                                  <span className="orange">25%</span> of UV
                                  radiation
                                </p>
                                <p>
                                  <span className="footNote">
                                    Source: Global Solar UV Index, WHO
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <h2>Effects of the Sun</h2>
                          <p>
                            The sun makes life on earth possible, but it can
                            also have harmful effects — especially without
                            sufficient sun protection.
                          </p>
                          <p> </p>
                          <h2>Immediate effects</h2>
                          <p>
                            <strong>Immediate pigmentation</strong>
                            <br />
                            The first colour-related effect of exposure to the
                            sun is known as "immediate pigmentation", or a
                            colouring that appears during exposure and
                            disappears within 2 hours. Caused by UVA and visible
                            light, immediate pigmentation does not protect
                            against sunburn.
                          </p>
                          <p> </p>
                          <p>
                            <strong>Persistent pigment darkening (PPD)</strong>
                          </p>
                          <p>
                            PPD appears during exposure and remains for up to
                            several days. It is not protective.
                          </p>
                          <p> </p>
                          <h2>Delayed effects</h2>
                          <p>
                            <strong>Sunburn</strong>
                            <br />
                            If you don't protect yourself from high doses of UV
                            rays, these rays will start penetrating the layers
                            of the skin. Eventually, blood vessels below the
                            skin's surface will expand and leak fluids, causing
                            inflammation and redness. Sunburn normally first
                            appears 2-6 hours after the start of exposure,
                            reaches its maximum intensity within 24-36 hours and
                            disappears after 3-5 days.
                          </p>
                          <p> </p>
                          <p>
                            <strong>Tanning (delayed pigmentation)</strong>
                            <br />
                            Tanning starts 3-5 days after sun exposure, when
                            special skin cells called melanocytes start
                            synthesising melanin, the body's natural tanning
                            pigment. Melanin is then transferred to neighbouring
                            cells called keratinocytes, which are the main cell
                            type in the epidermis or upper layer of your skin.
                            Unlike immediate pigmentation, actual tanning does
                            help protect against the harmful effects of UV
                            radiation. Tanning fades progressively, but remember
                            that people with a natural suntan also need to apply
                            sunscreen, as a tan does not provide sufficient
                            protection from UV exposure.
                          </p>
                          <p> </p>
                          <h2>Long-term effects</h2>
                          <p>
                            <strong>Skin ageing</strong>
                            <br />
                            Sun exposure plays a major role in the skin's ageing
                            process. Up to 90% of all visible signs of ageing
                            can be attributed to the harmful effects of sun
                            exposure.
                          </p>
                          <p> </p>
                          <p>
                            <strong>Skin cancer (malignant melanoma)</strong>
                            <br />
                            Skin cancer, or malignant melanoma, appears to be
                            caused by damage to the DNA structure of skin cells
                            that are exposed to the sun — with just 5% of all
                            carcinomas occurring on areas of the skin that are
                            not exposed. Damage to these skin cells leads to the
                            growth of degenerated cells that in turn result in
                            cancer. In Europe, skin cancer is far more prevalent
                            in the north, where people's skin tends to contain
                            less pigmentation.
                          </p>
                          <p> </p>
                          <h2>Accidental effects</h2>
                          <p>
                            <strong>Solar intolerances</strong>
                            <br />
                            The most common type of sun-induced skin eruption is
                            the benign summer light eruption (BSLE).
                            Particularly common among young women, BSLE appears
                            as small, itchy papules on the throat, upper chest
                            and back of the forearms. The condition appears
                            within hours after intense sun exposure and
                            disappears within a few days.
                          </p>
                          <div id="mountain" className=" ccm-block-styles">
                            <h2>Mountain Sun</h2>
                            <p>
                              <strong>
                                Why does the skin need special care in high
                                altitude conditions?
                              </strong>
                            </p>
                            <p>
                              <strong>
                                <br />
                              </strong>
                            </p>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_01_sun.png"
                                width="320"
                                height="325"
                                alt="Our guide to enjoying the sun safely"
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                For every <span className="orange">1,000</span>{" "}
                                metres you climb, the sun's rays grow in
                                intensity by up to{" "}
                                <span className="orange">12%</span>
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_02_sun.png"
                                width="320"
                                height="325"
                                alt="Our guide to enjoying the sun safely"
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Fresh snow reflects{" "}
                                <span className="orange">5X</span> more UV
                                radiation than dry beach sand
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_03_sun.png"
                                width="320"
                                height="325"
                                alt="Our guide to enjoying the sun safely"
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Up to <span className="orange">80%</span> of
                                solar radiation can penetrate light cloud cover
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_04_sun.png"
                                width="320"
                                height="325"
                                alt="Our guide to enjoying the sun safely"
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Cold, wind and dry air can decrease the
                                hydration level of the skin by{" "}
                                <span className="orange">13%</span>
                              </p>
                              <p className="ccm-note">
                                <span className="footNote">
                                  Sources: Global Solar UV Index, WHO and
                                  clinical evaluation of the hydration level, 12
                                  subjects
                                </span>
                              </p>
                            </div>
                          </div>
                          <div id="HTMLBlock891" className="HTMLBlock">
                            <div
                              style={{
                                clear: "both"
                              }}
                            />
                          </div>
                          <p>
                            <strong>The result</strong>
                            <br />
                            The skin easily dehydrates. It becomes tight, red
                            and sun stressed. Defending the skin against cold,
                            wind and intense sun requires a particular type of
                            protection.
                          </p>
                          <p> </p>
                          <p>
                            <strong>Use the right sunscreen</strong>
                            <br />
                            In the mountains, your skin requires a specific kind
                            of protection. Therefore, it is better to use a
                            sunscreen that helps to defend your skin against
                            cold, wind and intense sun.{" "}
                            <a href="/uk/our-products/mountain/">
                              PIZ BUIN<sup>®</sup> MOUNTAIN{" "}
                            </a>
                            helps to protect your skin in high altitude mountain
                            conditions.
                          </p>
                          <p>
                            Please see our general tips for safe, effective
                            sunscreen application.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">BE SAFE UNDER THE SUN</h2>
                        <p className="toggle__sub-title">
                          PIZ BUIN<sup>®</sup> suncare products give you the sun
                          protection you need, but what else can you do to
                          ensure the best possible safety under the sun?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h3>Sun Protection</h3>
                          <p> </p>
                          <p>
                            <strong>How do sun filters work?</strong>
                          </p>
                          <p>
                            The key technology used in any sunscreen is the UV
                            filter. UV filters can be either chemical or
                            physical.
                          </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/f47522e78906ab93bd6746d5c4ffa028_f95.png"
                              alt="kts_img_640_chemical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <p> </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Chemical filter
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Chemical filters work by absorbing UV light and
                            converting it to higher wavelength rays that are
                            less harmful to the skin.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/3abb2aaf80e1f6d050098a8ca6618a7a_f96.png"
                              alt="Our guide to enjoying the sun safely"
                              width="320"
                              height="330"
                            />
                          </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Physical filter
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Physical filters reflect, transmit or partially
                            absorb light and can protect against both UVA and
                            UVB radiation. The two most common physical filters
                            are titanium dioxide and zinc oxide.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <h2>What does Sun Protection Factor (SPF) mean?</h2>
                          <p>
                            The Sun Protection Factor (SPF) specified on your
                            bottle of sunscreen tells you how much protection
                            your sunscreen offers against UVB rays.
                          </p>
                          <p> </p>
                          <p>
                            In general, the higher the SPF number of your
                            sunscreen, the greater the protection, so an SPF 6
                            or 10 offers low protection, SPF 15, 20 or 25 offers
                            medium protection, SPF 30 or 50 offers high
                            protection, and SPF 50+ offers very high protection.
                            This is measured using a standardised test.
                          </p>
                          <p> </p>
                          <p>
                            The SPF number indicates how many times longer you
                            can stay in the sun before starting to burn. The way
                            it works is that you take the number of minutes it
                            normally takes your skin to burn in the sun with no
                            protection (let's say 15 minutes). Then, you
                            multiply that number by the SPF number (in this
                            case, Factor 6). Since 15 x 6 = 90, you should have
                            90 minutes before you start to get a sun burn. In
                            other words, the higher the SPF the greater the
                            protection of your sunscreen. But remember these are
                            theoretical values. In reality, the time depends,
                            among other factors, on your skin phototype and the
                            local UV intensity.
                          </p>
                          <p> </p>
                          <p>
                            To choose the right SPF, you need to consider
                            several factors. The most important of these is your
                            skin's{" "}
                            <a title="See skin types" href="#skintypes">
                              phototype
                            </a>
                            , which includes the colour of your skin before
                            exposure to the sun, your hair and eye colour and
                            other factors. You also need to think about where
                            you are going on holiday and what time of year it
                            is.
                          </p>
                          <p> </p>
                          <h2>What does "Photostable" protection mean?</h2>
                          <p>
                            To protect the skin, sunscreen uses UV filters that
                            absorb radiation from the sun. When a sunscreen is
                            exposed to this radiation, it can alter the chemical
                            structure of the UV filters, thus degrading their
                            protective capabilities.
                          </p>
                          <p> </p>
                          <p>
                            A "photostable" sunscreen is able to withstand the
                            effects of UV exposure better, so it keeps its level
                            of protection.
                          </p>
                          <p> </p>
                          <p>
                            All PIZ BUIN<sup>®</sup> sun protection products
                            provide photostable protection.
                          </p>
                          <p> </p>
                          <h2>What does "broad-spectrum protection" mean?</h2>
                          <p>
                            A sunscreen with broad-spectrum protection means it
                            has the ability to protect against the harmful
                            effects of both UVA (ageing rays) and UVB (burning
                            rays). To be classified as offering broad-spectrum
                            protection, a sunscreen product needs to absorb or
                            reflect at least 90% of the UV rays from the 290 to
                            400 nanometres (nm) wavelength range.
                          </p>
                          <p> </p>
                          <p>
                            All PIZ BUIN<sup>®</sup> sun protection products
                            provide broad-spectrum protection.
                          </p>
                          <p> </p>
                          <h2>Water resistance</h2>
                          <p>
                            It's important that your sunscreen is water
                            resistant when you go swimming because UV radiation
                            half a metre below the surface of water is still 40%
                            as intense as it is on the surface. You also need to
                            protect the part of your body that's above the
                            surface, as water reflects some 25% of UV radiation
                            and can intensify the harmful effects.
                          </p>
                          <p>
                            Almost all* PIZ BUIN<sup>®</sup> sun protection
                            products range from being water resistant to extra
                            water resistant like our PIZ BUIN WET SKIN
                            <sup>®</sup> Transparent Sun Spray.
                          </p>
                          <p className="footNote">
                            *The following product is not water resistant:
                            Allergy Spray SPF30.
                          </p>
                          <p className="footNote"> </p>
                          <h2>Sun Safe Tips</h2>
                          <p>
                            Going on holiday often means exposing your skin to
                            the sun for the first time after a long winter of
                            cold weather and warm clothes. It's important to
                            devote extra attention to your skin before you leave
                            to make sure it is ready for the sudden change.
                          </p>
                          <p> </p>
                          <p>
                            <strong>
                              Tips for safe and effective sunscreen application
                              and sun behaviour:
                            </strong>
                          </p>
                          <ol>
                            <li>
                              <span>
                                Apply sunscreen at least 20 minutes before going
                                out in the sun to allow maximum absorption and
                                protection.
                              </span>
                            </li>
                            <li>
                              <span>
                                Make sure you're applying enough sunscreen. An
                                insufficient quantity lowers the level of
                                protection significantly. Pay special care to
                                more sensitive areas, such as your ears, nose
                                shoulders, cleavage and neck.
                              </span>
                            </li>
                            <li>
                              <span>
                                Don't neglect hard to reach and easily forgotten
                                places, such as your ears, feet and upper back.
                              </span>
                            </li>
                            <li>
                              <span>
                                Re-apply sunscreen every 2 hours. Apply more
                                frequently after swimming, sweating or
                                towelling.
                              </span>
                            </li>
                            <li>
                              <span>
                                Try to avoid intense midday sun from 11:00am and
                                3:00pm during the summer months or when on
                                holiday in tropical countries.
                              </span>
                            </li>
                            <li>
                              <span>
                                If you are taking medication, check with your
                                doctor or pharmacist to make sure it's OK to
                                spend time in the sun. Some medicines can make
                                the skin more sensitive to the sun's rays.
                              </span>
                            </li>
                            <li>
                              <span>
                                Overexposure to the sun can threaten your
                                health, so avoid staying out in the sun too
                                long, even when using a sunscreen.
                              </span>
                            </li>
                            <li>
                              <span>
                                Protect children. Children are particularly
                                vulnerable to the effects of UV radiation and
                                often spend more time outdoors than adults.
                                Parents should take special care to protect them
                                from the sun using protective clothing, hats,
                                sunglasses and sunscreen. Keep babies and young
                                children out of direct sunlight.
                              </span>
                            </li>
                          </ol>
                          <p>
                            <a name="skintypes" />
                          </p>
                          <h2>Skin Types</h2>
                          <p>
                            Different skin types react to the sun in different
                            ways, so the type of skin you have influences how
                            long you can stay in the sun and what types of
                            sunscreen you need.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">FAQ</h2>
                        <p className="toggle__sub-title">
                          What else should I know about using PIZ BUIN
                          <sup>®</sup> suncare products?{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>
                            Will using sunscreen stop me from getting a tan?
                          </h2>
                          <p>
                            Quite the reverse is true. Although your skin
                            changes colour more slowly when you're wearing
                            sunscreen, the resulting tan will be better and
                            longer-lasting because the sunscreen will prevent
                            your skin from burning and peeling. Some PIZ BUIN
                            <sup>®</sup> products even contain special
                            ingredients that stimulate the natural production
                            and the even distribution of tanning pigments in the
                            skin.
                          </p>
                          <p> </p>
                          <h2>
                            How long does sunscreen last before it goes out of
                            date?
                          </h2>
                          <p>
                            It depends on whether or not the bottle has been
                            opened. All PIZ BUIN<sup>®</sup> sunscreen is tested
                            according to EU legislation and has a useful life
                            span of more than 30 months. If your bottle has been
                            opened for more than 12 months, you will need to
                            purchase a new bottle.
                          </p>
                          <p> </p>
                          <h2>
                            Can I decrease the level of SPF as I spend more time
                            in the sun? How quickly?
                          </h2>
                          <p>
                            You can decrease your protection level step-by-step
                            during your holiday as soon as your skin has
                            produced its own defence system: the tan. After a
                            few days of effective protection, try moving from a
                            high protection product to a medium one. But
                            remember that people with a natural suntan also need
                            to apply sunscreen as a tan does not provide
                            sufficient protection from UV exposure. Also bear in
                            mind, that the SPF level you need largely depends on
                            your skin phototype and the local UV intensity.
                          </p>
                          <p>
                            If you are unsure about the right SPF level for your
                            sunscreen, always choose the higher one.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
       
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\nif(document.location.hash != ''){\nvar anchor = document.location.hash;\n$(\"#container_thesun\").show();\nt = $(anchor);\n$('html,body').animate({scrollTop: t.offset().top},'slow');\n}\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
